import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'

import CSVImportModal from './csv-import-modal'
import { overseer } from '../../utils/api'


const propTypes = { addDomains: PropTypes.func.isRequired, enableReplace: PropTypes.bool }
const defaultProps = { enableReplace: true }

class CSVImportModalContainer extends Component {
  state = {
    file: null,
    isDomainID: false,
    actionType: 'add',
    fileName: '',
    open: false,
    isLoading: false,
    found: [],
    notFound: [],
    domainsFetched: false,
  }

  openModal = () => {
    this.setState({
      file: null,
      fileName: '',
      isLoading: false,
      found: [],
      notFound: [],
      open: true,
      domainsFetched: false,
      actionType: 'add',
    })
  }

  closeModal = () => this.setState({ open: false, actionType: 'add' })

  handleFileUpload = (files) => {
    const fileTypes = ['text/csv', 'application/vnd.ms-excel']
    if (files.length === 0 || !fileTypes.includes(files[0].type)) {
      return
    }

    const data = new FormData()
    data.append('file', files[0], files[0].name)

    this.setState({ file: data, fileName: files[0].name })
  }

  updateDomain = ({ field, value }) => {
    this.setState({
      [field]: value,
      domainsFetched: false,
      found: [],
      notFound: [],
    })
  }

  fetchDomains = async () => {
    const { file, isDomainID } = this.state

    this.setState({ isLoading: true })

    try {
      const res = await overseer({
        url: `/search/domains?is_domainID=${isDomainID}`,
        method: 'post',
        data: file,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      this.setState({
        isLoading: false,
        found: res.data.found,
        notFound: res.data.not_found,
        domainsFetched: true,
      })
    } catch (err) {
      this.setState({ isLoading: false })
      toast.error('Error fetching domains from csv file')
    }
  }

  genFoundCSV() {
    const { found } = this.state
    const domains = found.map(({ TLD }) => TLD)

    let csvContent = 'data:text/csv;charset=utf-8,'

    domains.forEach((tld) => {
      csvContent += `${tld}\r\n`
    })

    return csvContent
  }

  genNotFoundCSV() {
    const { notFound: domains } = this.state

    let csvContent = 'data:text/csv;charset=utf-8,'

    domains.forEach((tld) => {
      csvContent += `${tld}\r\n`
    })

    return csvContent
  }

  addDomainsWrapper = () => {
    const { found, actionType } = this.state
    const { addDomains } = this.props
    addDomains(found, actionType)
    this.setState({ open: false })
  }

  render() {
    const {
      file,
      fileName,
      isDomainID,
      open,
      isLoading,
      found,
      notFound,
      domainsFetched,
      actionType,
    } = this.state

    const notFoundCSV = this.genNotFoundCSV()
    const foundCSV = this.genFoundCSV()

    return (
      <CSVImportModal
        addDomains={this.addDomainsWrapper}
        closeModal={this.closeModal}
        fetchDomains={this.fetchDomains}
        handleFileUpload={this.handleFileUpload}
        openModal={this.openModal}
        file={file}
        isDomainID={isDomainID}
        actionType={actionType}
        updateDomain={this.updateDomain}
        fileName={fileName}
        open={open}
        isLoading={isLoading}
        found={found}
        notFound={notFound}
        domainsFetched={domainsFetched}
        foundCSV={foundCSV}
        notFoundCSV={notFoundCSV}
        enableReplace={this.props.enableReplace}
      />
    )
  }
}

CSVImportModalContainer.propTypes = propTypes
CSVImportModalContainer.defaultProps = defaultProps

export default CSVImportModalContainer
